import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import( /* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Login = () => import( /* webpackChunkName: "dashboard" */ "@/pages/Login.vue");
const TrueWallet = () => import( /* webpackChunkName: "common" */ "@/pages/TrueWallet.vue");
const Scbbank = () => import( /* webpackChunkName: "common" */ "@/pages/Scbbank.vue");
const Sms = () => import( /* webpackChunkName: "common" */ "@/pages/Sms.vue");
const MemberLoss = () => import( /* webpackChunkName: "common" */ "@/pages/MemberLoss.vue");
const Costomer = () => import( /* webpackChunkName: "common" */ "@/pages/Userlist.vue");
const CostomerInfo = () => import( /* webpackChunkName: "common" */ "@/pages/costomer/info.vue"); 
const Friendspoint = () => import( /* webpackChunkName: "common" */ "@/pages/pointFriends.vue");
const FriendspointDetail = () => import( /* webpackChunkName: "common" */ "@/pages/pointFriendsDetail.vue");
const Modlist = () => import( /* webpackChunkName: "common" */ "@/pages/Modlist.vue");
const Listwithdraw = () => import( /* webpackChunkName: "common" */ "@/pages/Listwithdraw.vue");
const ListTransferFail = () => import( /* webpackChunkName: "common" */ "@/pages/ListTransferFail.vue");
const ListTransferSuccess = () => import( /* webpackChunkName: "common" */ "@/pages/ListTransferSuccess.vue");
const ListTransferCredit = () => import( /* webpackChunkName: "common" */ "@/pages/ListTransferCredits.vue");
const ListGetMoney = () => import( /* webpackChunkName: "common" */ "@/pages/ListGetMoney.vue");
const Controlgame = () => import( /* webpackChunkName: "common" */ "@/pages/Controlgame.vue");
const Controlbank = () => import( /* webpackChunkName: "common" */ "@/pages/Controlbank.vue");
const LiveChat = () => import( /* webpackChunkName: "common" */ "@/pages/LiveChatPage.vue");
const ControlTagGames = () => import( /* webpackChunkName: "common" */ "@/pages/TagGames.vue");
const AFLink = () => import( /* webpackChunkName: "common" */ "@/pages/afLink/afLink.vue");
const AFLinkInfo = () => import( /*webpackChunkName : "common" ,  */ "@/pages/afLink/afLink_info.vue");
const Promotion = () => import( /* webpackChunkName: "common" */ "@/pages/Promotion.vue"); 
const Freespin = () => import( /* webpackChunkName: "common" */ "@/pages/Freespin.vue");
const LogBof = () => import( /* webpackChunkName: "common" */ "@/pages/LogBof.vue");

//// WWWW PAGE
const InfoHomePage = () => import( /* webpackChunkName: "common" */ "@/pages/wwwPage/InfoHomePage.vue");
const ImageSlide = () => import( /* webpackChunkName: "common" */ "@/pages/wwwPage/ImageSlide.vue");
const ImagePromotion = () => import( /* webpackChunkName: "common" */ "@/pages/wwwPage/ImagePromotion.vue");
const ImageJackpot = () => import( /* webpackChunkName: "common" */ "@/pages/wwwPage/ImageJackpot.vue");

//// Game Log
const GamesLogs = () => import( /* webpackChunkName: "common" */ "@/pages/GameLogs/Index_gamelogs.vue");
const GamesOption = () => import( /* webpackChunkName: "common" */ "@/pages/GameLogs/GamesOption.vue");

//// Agent info
const AgentInfo = () => import( /* webpackChunkName: "common" */ "@/pages/AgentInfo/AgentInfo.vue");

const ConfirmPage = () => import(/* webpackChunkName: "common" */ "@/pages/ConfirmPage.vue");
const Record = () => import(/* webpackChunkName: "common" */ "@/pages/Record.vue");
const RecordBank = () => import(/* webpackChunkName: "common" */ "@/pages/RecordBank.vue");
const Winlose = () => import(/* webpackChunkName: "common" */ "@/pages/Winlose.vue");
const ListCostummerWithdraw = () => import(/* webpackChunkName: "common" */ "@/pages/ListCostummerWithdraw.vue");
const ListMemberSMS = () => import(/* webpackChunkName: "common" */ "@/pages/ListMemberSMS.vue");



// const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import( /* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Help = () => import( /* webpackChunkName: "common" */ "@/pages/Help/index.vue");
// const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
// const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
// const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

///// report_finance
const FINANCE_CREDIT = () => import("@/pages/report_finance/finance_credit_view.vue");
const FINANCE_DEPOSIT_CREDIT = () => import("@/pages/report_finance/finance_deposit_credit_view.vue");
const FINANCE_WITHDRAW_CREDIT = () => import("@/pages/report_finance/finance_withdraw_credit_view.vue");

///// report_members
const REPORT_MEMBERS_WITHDRAW = () => import("@/pages/report_members/report_members_withdraw_view.vue");
const REPORT_MEMBERS_NOT_DEPOSIT = () => import("@/pages/report_members/report_members_not_deposit_view.vue");
const REPORT_MEMBERS_PROMOTION = () => import("@/pages/report_members/report_members_promotion_view.vue");
const REPORT_MEMBERS_PROMOTION_FREE = () => import("@/pages/report_members/report_members_promotion_free_view.vue");
const REPORT_MEMBERS_FIXED_DEPOSIT = () => import("@/pages/report_members/report_members_fixed_deposit_view.vue");
const REPORT_MEMBERS_ONE_DEPOSIT = () => import("@/pages/report_members/report_members_one_deposit_view.vue");
const REPORT_MEMBERS_FRIEND = () => import("@/pages/report_members/report_members_friend_view.vue");
const REPORT_MEMBERS_LOSE = () => import("@/pages/report_members/report_members_lose_view.vue");
const REPORT_MEMBERS_EXPIRED7DAY = () => import("@/pages/report_members/report_members_expired7day_view.vue");

import utils from '@/utils';

const routes = [{ //// Login
    path: '/login',
    name: 'เข้าสู่ระบบ',
    component: Login,
    beforeEnter: (to, from, next) => {
      let token = (utils.localDB.act('token'))?true:false;
      if (!token) {
        next();
      } else {
        next({
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard
        });
      }
    }
  },
  ///// Dashboard
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    beforeEnter: (to, from, next) => {
      let token = (utils.localDB.act('token'))?true:false;
      if (token) { 
        next();
      } else {
        next({
          path: '/login',
          name: 'Login',
          component: Login
        });
      }
    },
    children: [{
        path: "dashboard",
        name: "แผงควบคุม",
        component: Dashboard 
      },
      /// TrueWallet List
      {
        path: "TrueWallet",
        name: "รายการทรูวอเรท",
        component: TrueWallet
      },
      //// List Get Money
      {
        path: "listGetMoney",
        name: "เพิ่มเงินให้ลูกค้า",
        component: ListGetMoney
      },
      //// List game
      {
        path: "controlgame",
        name: "รายการเกม",
        component: Controlgame
      },
      //// List Promotion
      {
        path: "promotion",
        name: "จัดการโปรโมชั่น",
        component: Promotion
      }, 
      //// List Bank
      {
        path: "livechat",
        name: "Live Chat",
        component: LiveChat
      },
      {
        path: "configbank",
        name: "จัดการธนาคาร",
        component: Controlbank
      },
      //// listTransferCredit
      {
        path: "listTransferCredit",
        name: "รายการใช้งานโปรโมชั่น",
        component: ListTransferCredit
      },
      //// list Transfer Fail
      {
        path: "listTransferFail",
        name: "รายการฝากไม่สำเร็จ",
        component: ListTransferFail
      },
      //// list Transfer Success
      {
        path: "listTransferSuccess",
        name: "รายการฝากสำเร็จ",
        component: ListTransferSuccess
      },
      /// SCB BANK LIST Transfer
      {
        path: "Scbbank",
        name: "รายการธนาคาร",
        component: Scbbank
      },
      /// Member Loss
      {
        path: "memberloss",
        name: "รายการผู้ขอคืนยอดเสีย",
        component: MemberLoss
      },
      /// SMS List
      {
        path: "smsScb",
        name: "ข้อความ",
        component: Sms
      },
      /// SMS List Member
      {
        path: "listMemberSMS",
        name: "รายการส่ง SMS",
        component: ListMemberSMS
      },
      /// Costomer List
      {
        path: "costomer",
        name: "ข้อมูลสมาชิก",
        component: Costomer
      },
      /// Costomer Info
      {
        path: "costomer/:id",
        name: "ข้อมูลสมาชิก",
        component: CostomerInfo
      },
      /// Friends point
      {
        path: "friendspoint",
        name: "ชวนเพื่อน",
        component: Friendspoint
      },
      {
        path: "friendspointdetail",
        name: "รายละเอียดเพื่อนเพื่อน",
        component: FriendspointDetail
      },
      /// Admin List
      {
        path: "modlist",
        name: "รายชื่อผู้ดูแลระบบ",
        component: Modlist
      },
      /// List Withdraw
      {
        path: "listwithdraw",
        name: "รายการถอน",
        component: Listwithdraw
      },
      /// HELP
      {
        path: "help",
        name: "ช่วยเหลือ", 
        component: Help
      },
      /// Info Home Page
      // {
      //   path: "infoHomePage",
      //   name: "InfoHomePage",
      //   component: InfoHomePage,
      //   beforeEnter: (to, from, next) => {
      //     let token = (utils.localDB.act('token'))?true:false;
      //     if (token) {
      //       next();
      //     } else {
      //       next({
      //         path: '/login',
      //         name: 'Login',
      //         component: Login
      //       });
      //     }
      //   }
      // },
      /// image Slide
      // {
      //   path: "imageSlide",
      //   name: "imageSlide",
      //   component: ImageSlide,
      //   beforeEnter: (to, from, next) => {
      //     let token = (utils.localDB.act('token'))?true:false;
      //     if (token) {
      //       next();
      //     } else {
      //       next({
      //         path: '/login',
      //         name: 'Login',
      //         component: Login
      //       });
      //     }
      //   }
      // },
      /// image Promotion
      // {
      //   path: "imagePromotion",
      //   name: "imagePromotion",
      //   component: ImagePromotion,
      //   beforeEnter: (to, from, next) => {
      //     let token = (utils.localDB.act('token'))?true:false;
      //     if (token) {
      //       next();
      //     } else {
      //       next({
      //         path: '/login',
      //         name: 'Login',
      //         component: Login
      //       });
      //     }
      //   }
      // },
      /// Image Jackpot
      // {
      //   path: "imageJackpot",
      //   name: "imageJackpot",
      //   component: ImageJackpot,
      //   beforeEnter: (to, from, next) => {
      //     let token = (utils.localDB.act('token'))?true:false;
      //     if (token) {
      //       next();
      //     } else {
      //       next({
      //         path: '/login',
      //         name: 'Login',
      //         component: Login
      //       });
      //     }
      //   }
      // },
      /// Spin Wheel logs
      {
        path: "freespin",
        name: "วงล้อหรรษาพาโชค",
        component: Freespin
      },
      /// Logs
      {
        path: "logs",
        name: "บันทึก",
        component: LogBof
      },
      /// Logs Game
      {
        path: "gamelog",
        name: "บันทึกเกม",
        component: GamesLogs
      },
      /// Report finance
      {
        path: "finance_credit_view",
        name: "รายการฝากเครดิต",
        component: FINANCE_CREDIT
      },
      {
        path: "finance_deposit_credit_view",
        name: "รายการฝากเครดิต",
        component: FINANCE_DEPOSIT_CREDIT
      },
      {
        path: "finance_withdraw_credit_view",
        name: "รายการถอนเครดิต",
        component: FINANCE_WITHDRAW_CREDIT
      },
      // ///// report_members 
      {
        path: "report_members_withdraw_view",
        name: "รายงานฝากถอนสมาชิก",
        component: REPORT_MEMBERS_WITHDRAW
      },
      {
        path: "report_members_not_deposit_view",
        name: "รายงานสมาชิกไม่ฝากเงิน",
        component: REPORT_MEMBERS_NOT_DEPOSIT
      },
      {
        path: "report_members_promotion_view",
        name: "รายงานโปรโมชั่น",
        component: REPORT_MEMBERS_PROMOTION
      },
      {
        path: "report_members_promotion_free_view",
        name: "รายงานกรอกโค๊ดโปรโมชั่น",
        component: REPORT_MEMBERS_PROMOTION_FREE
      },
      {
        path: "report_members_fixed_deposit_view",
        name: "รายงานสมาชิกฝากประจำ",
        component: REPORT_MEMBERS_FIXED_DEPOSIT
      },
      {
        path: "report_members_one_deposit_view",
        name: "รายงานสมาชิกฝากครั้งเดียว",
        component: REPORT_MEMBERS_ONE_DEPOSIT
      },
      {
        path: "report_members_friend_view",
        name: "รายงานแนะนำเพื่อน",
        component: REPORT_MEMBERS_FRIEND
      },
      {
        path: "report_members_lose_view",
        name: "รายงานรับยอดเสีย",
        component: REPORT_MEMBERS_LOSE
      },
      {
        path: "report_members_expired7day_view",
        name: "รายงานลูกค้าหายไป 7 วัน",
        component: REPORT_MEMBERS_EXPIRED7DAY
      },
      
      // Games Option
      // {
      //   path: "gamesoption",
      //   name: "gamesoption",
      //   component: GamesOption,
      //   beforeEnter: (to, from, next) => {
      //     let token = (utils.localDB.act('token'))?true:false;
      //     if (token) {
      //       next();
      //     } else {
      //       next({
      //         path: '/login',
      //         name: 'Login',
      //         component: Login
      //       });
      //     }
      //   }
      // },
      // Agent Info
      {
        path: "agentinfo",
        name: "agentinfo",
        component: AgentInfo
      },
      // CONFIRM PAGE
      {
        path: "confirmpage",
        name: "รายการรออนุมัติ",
        component: ConfirmPage
      },
      // CONFIRM PAGE
      {
        path: "taggame",
        name: "หมวด",
        component: ControlTagGames
      },
      // record ประวัติย้อนหลัง
      {
        path: "record",
        name: "ประวัติย้อนหลัง",
        component: Record
      },
      // record Bank ประวัติย้อนหลัง
      {
        path: "recordbank",
        name: "ประวัติย้อนหลัง",
        component: RecordBank
      },

      // Winlose Winlose
      {
        path: "winlose",
        name: "แพ้-ชนะ",
        component: Winlose
      },
      //// ListCostummerWithdraw ลูกค้าแจ้งฝาก
      {
        path: "listCostummerWithdraw",
        name: "ลูกค้าแจ้งฝาก",
        component: ListCostummerWithdraw
      },
      //// ลิ้งค์ 
      {
        path: "AFLink",
        name: "ตรวจสอบลิ้งค์ AF",
        component: AFLink
      },
      {
        path: "AFLink/view/:id",
        name: "ตรวจสอบลิ้งค์ AF",
        component: AFLinkInfo
      },  
      {
        path: "icons",
        name: "icons",
        component: Icons
      }, 
    ]
  },
  ///// ERROR Page
  {
    path: "*",
    component: NotFound
  },
];
 
 
export default routes;
