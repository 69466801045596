<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="(column,index) in columns" :key="column">
          <button class="sorttable" @click="SortTable(column)"> {{columnsTh[index]}} 
            <i v-show="sort.col != column && (sort.even == 'desc' || sort.even == 'aec')" class="fa-solid fa-sort"></i>
            <i v-show="sort.col == column && sort.even == 'desc'" class="fa-solid fa-sort-up"></i>
            <i v-show="sort.col == column && sort.even == 'aec'" class="fa-solid fa-sort-down"></i>
          </button>
        </th>
        <th>Event</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index">
      <slot :row="item">
        <td v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column)" v-html="itemValue(item, column) || '0'">
        </td>
        <td class="td-actions" v-if="item.status == 'wait'">
          <!-- <base-button type="danger"  title="ข้อมูลเกม" style="margin-right: 5px;" icon size="sm" @click="logGame(item)">
            <i class="tim-icons icon-controller"></i>
          </base-button> -->
          <base-button type="danger" v-if="item.promotion != '-'" title="ข้อมูล" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'info','item':item})">
            <i class="tim-icons icon-paper"></i>
          </base-button>
          <base-button type="info" v-if="item.promotion == '-'" title="ข้อมูล" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'info','item':item})">
            <i class="tim-icons icon-paper"></i>
          </base-button>
          <base-button type="success" title="โอน" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'withdraw','item':item})">
            <i class="tim-icons icon-coins"></i>
          </base-button>
          <base-button type="primary" title="ปฏิเสธ" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'rejact','item':item})">
            <i class="tim-icons icon-simple-remove"></i>
          </base-button>
        </td>
        <td class="td-actions" v-if="item.status != 'wait'">
          <base-button type="default" title="ผู้กระทำ" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'activeInfo','item':item})">
            <i class="tim-icons icon-single-02"></i>
          </base-button>
          <base-button v-if="item.status == 'failed'" type="info" title="รายงาน" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'Logs','item':item})">
            <i class="tim-icons icon-bullet-list-67"></i>
          </base-button>
          <!-- <base-button v-if="permission('CONFIRMPAGE_EDIT') && (item.status == 'rejact' || item.status == 'failed')" title="ทำรายการใหม่"  type="primary" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'return','item':item})">
            <i class="tim-icons icon-refresh-01"></i>
          </base-button> -->
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import config from '@/config.js';
  import utils from '@/utils';
  export default {
    name: 'base-table-withdraw',
    data() {
      return {
        permission: utils.permission
      }
    },
    props: {
      sort:{
        type: Object,
        default: () => {},
      },
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      columnsTh: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      }, 
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      logGame(item){
        window.open(`/?gid=${item.gameId}#/gamelog`);
      },
      hasValue(item, column) {
        return typeof item[column];
        //return item[column] !== "undefined";
      },
      modalsUser(item){
        this.$emit("withd-modals",item);
      },
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      itemValue(item, column) {
        if(column == 'created_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column ==  'updated_at'){ 
          let c = utils.forMatDateTimes(new Date(item['created_at']));
          let u = utils.forMatDateTimes(new Date(item[column]));
          return (c == u)?'-':u;
        }else if(column == 'iconBank'){
          return `<img class="icons-sys" src="${config.iconBank[item[column]]}" />`
        }else if(column == 'credit' || column == 'withMax'){
          return (isNaN(parseFloat(item[column]).toFixed(2)))?'-':parseFloat(item[column]).toFixed(2);
        }else if(column == 'withdraw'){
          if(item.promotion == '-'){
            return (isNaN(parseFloat(item[column]).toFixed(2)))?'-':parseFloat(item[column]).toFixed(2);
          }
          else{
            if(parseFloat(item.credit) > parseFloat(item.withMax)){
              return (isNaN(parseFloat(item.withMax).toFixed(2)))?'-':parseFloat(item.withMax).toFixed(2);
            }
            else{
              // return `${item[column]} / ${item.withMax}`
              return (isNaN(parseFloat(item.credit).toFixed(2)))?'-':parseFloat(item.credit).toFixed(2);
            }
          }
        }else{
          let text = '';
          switch (item[column]) {
            case 'wait':
              text = '<span class="tale-status-wait"> รอ </span>';
              break;
            case 'active':
              text = '<span class="tale-status-active"> เสร็จสิ้น </span>';
              break;
            case 'confirm':
              text = '<span class="tale-status-wait"> รอการยืนยัน </span>';
              break;
            case 'rejact':
               text = '<span class="tale-status-reject"> ปฏิเสธ </span>';
               break;
            case 'failed':
              text = '<span class="tale-status-failed"> ล้มเหลว </span>';
              break;
            default :
              text = item[column];
          }
          return text;
        }

      },
      SortTable(column){ 
        this.$emit('SortTable',column);
      }
    }
  };
</script>
<style>
.tale-status-wait{
  color: #FFF;
  background-color: #ff9f89;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
}
.tale-status-active{
  color: #FFF;
  background-color: #00bf9a;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
}
.tale-status-reject{
  color: #FFF;
  background-color: #fd77a4;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
}
.tale-status-failed{
  color: #FFF;
  background-color: #ec250d;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
}
</style>
