<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="(column, index) in columnth" :key="column">
            <button class="sorttable" @click="SortTable(columns[index])">
              {{ fomatColum(column) }}
              <i
                v-show="sort.col != column && (sort.even == 'desc' || sort.even == 'aec')"
                class="fa-solid fa-sort"
              ></i>
              <i
                v-show="sort.col == column && sort.even == 'desc'"
                class="fa-solid fa-sort-up"
              ></i>
              <i
                v-show="sort.col == column && sort.even == 'aec'"
                class="fa-solid fa-sort-down"
              ></i>
            </button>
          </th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <template v-if="data.length > 0">
      <tr v-for="(item, index) in data" :key="index" :class="item.type">
        <slot :row="item">
          <td
            v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column)"
            v-html="itemValue(item, column)"
            @click="opendetail(item)"
          ></td>
        </slot>
      </tr>
    </template>
    <template v-else>
        <tr>
          <td :colspan="columns.length" class="text-center">ไม่มีการทำรายการของวันที่เลือก</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script>
import utils from "@/utils";
export default {
  name: "base-table-finance",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    columnth: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    sort: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    title: {
      type: String,
      default: "รายการ",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    formatDate(timestamp) {
      let timeS = timestamp ? new Date(timestamp) : new Date();
      let d = timeS,
        hour = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return `${[year, month, day].join("-")} ${hour}:${minutes}:${seconds}`;
    },
    opendetail(item) {
      if (item.refer.col == "lose_log") {
        this.$emit("OpenDetail", item);
        // console.log(item);
      }
      if (item.refer.col == "get_money_friends") {
        //console.log(item.refer.id);
        window.location.href = `/?#/friendspointdetail?id=${item.refer.id}`;
      }
    },
    fomatColum(column) {
      return column; //this.title[column];
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
    if (column == "created_at" || column == "updated_at") {
        return utils.forMatDateTimes(new Date(item[column]));
    } else if (column == "refer") {
      if(item[column].col == 'lose_log' || item[column].col == 'get_money_friends'){
          return `<span style="cursor: pointer; color: blue; text-decoration: underline;">${item[column].col}-${item[column].id}</span>`;
      }
      return `${item.refer.col}-${item.refer.id}`;
    } else if(column == "event"){
      if(item[column] == "deposit"){
        return `<center><span class="badge badge-success">ฝาก</span></center>`;
      }
      else if(item[column] == "withdraw"){
        return `<center><span class="badge badge-danger">ถอน</span></center>`;
      }
      else if(item[column] == "lose"){
        return `<center><span class="badge badge-warning">ยอดเสีย</span></center>`;
      }
      else if(item[column] == "transfers"){
        return `<center><span class="badge badge-info">โยกกระเป๋า</span></center>`;
      }
      else if(item[column] == "modify"){
        return `<center><span class="badge badge-dark">ปรับยอดโดยแอดมิน</span></center>`;
      }
      else if(item[column] == "usepro"){
        return `<center><span class="badge badge-secondary">ใช้โปร</span></center>`;
      }
      else if(item[column] == "wheel"){
        return `<center><span class="badge badge-primary">วงล้อ</span></center>`;
      }
      
      else{
        return item[column];
      }
    }else {
        return item[column];
      }
    },
    SortTable(column) {
      this.$emit("SortTable", column);
    },
  },
};
</script>
<style scoped>
.deposit {
  background: rgb(45 206 137 / 30%) !important;
}
.withdraw {
  background: rgb(245 54 92 / 30%) !important;
}
</style>
