<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{formatColumn(column)}}</th>
        <th>เปลี่ยนโปร</th>
        <th>Event</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index" v-bind:class="{ blockUser: (!item.status) }">
      <slot :row="item">
        <td v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column)" v-html="itemValue(item, column)">
        </td>
        <td>
          <base-button title="เปลี่ยนโปรโมชั่น" size="sm" @click="modalsUser({'eve':'changepro','item':item})" type="success" style="width: 100%;">
            เลือกโปร
          </base-button>
        </td>
        <td class="td-actions">
          <!-- <base-button type="warning" title="แก้ไขเครดิต" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'ceditAgent','item':item})">
            <i class="tim-icons icon-coins"></i>
          </base-button> -->
          <base-button type="info" title="แก้ไขสมาชิก" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'info','item':item})">
            <i class="tim-icons icon-paper"></i>
          </base-button>
          <base-button title="รายละเอียดสมาชิก" type="default" style="margin: 0px 5px;" icon size="sm" @click="modalsUser({'eve':'memberlog','item':item})">
              <i class="tim-icons icon-bullet-list-67"></i>
            </base-button>
          <!-- <base-button type="success" title="ฟรีโค๊ต" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'vocher','item':item})">
            <i class="tim-icons icon-tag"></i>
          </base-button> -->
          <base-button type="warning" title="แต้มที่ได้จากเพื่อน" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'pointFriends','item':item})">
            <i class="tim-icons icon-vector"></i>
          </base-button>
          <!-- <base-button type="default" title="แก้ไขเครดิตในเกม" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'ceditAgent','item':item})">
            <i class="tim-icons icon-controller"></i>
          </base-button> -->
          <base-button type="danger" title="บ๊อค/ปลดบล็อค" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'block','item':item})">
            <i class="tim-icons icon-lock-circle"></i>
          </base-button>
          <base-button type="primary" title="โยกเงินจากกระเป๋าสำรอง" style="margin-right: 5px;" icon size="sm" @click="modalsUser({'eve':'tranferbalance','item':item})" v-if="item.status && item.balance > 0">
            <i class="tim-icons icon-coins"></i>
          </base-button>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import config from '../config.js';
  export default {
    name: 'base-table-true-wallet',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      OpenDetail(item){
        this.$emit('openDetail',item);
      },
      hasValue(item, column) {
        return typeof item[column.toLowerCase()];
        //return item[column.toLowerCase()] !== "undefined";
      },
      modalsUser(item){
        this.$emit("user-modals",item)
      },
      formatColumn(column){
        let Data = {
          "name":"ชื่อ",
          "account":"ไอดีเกม",
          "credit":"กระเป๋าหลัก",
          "balance":"กระเป๋าสำรอง",
          "typeBank":"ธนคาร",
          "numberBank":"เลขบัญชี",
          "deposit":"ยอดฝาก", 
          "withdraw":"ยอดถอน",
          "userCode":"โค๊ตฟรี"
        };
        return Data[column];
      },
      itemValue(item, column) {
        if(config.iconBank[item[column]]){
          return `<img class="icons-sys" src="${config.iconBank[item[column]]}" />`
        }else if(column == 'name'){
          let text = '';
          switch (item['status']) {
            case 0 :
              text = `<span class="status-icon tale-status-reject"> B </span> ${item[column]}`;
              break;
            default :
              text = item[column];
          }
          return text;
        }else if(column == 'userCode'){
          if(item[column].length){
            //return item[column][0].status;
           if(item[column][0].status == 'active'){
             return `<span class="tale-status-active"> ${item[column][0].codekey} </span>`;
           }else{
             return '<span class="tale-status-wait"> Wait </span>';
           }
          }else{
            return '<span class="tale-status-reject"> Ready </span>';
          }
        }else if(column == 'transfers'){
          let d = item.deposit ;
          let w = item.withdraw ;
          if (w > d) {
            return `<span style="color: #38ff00;">+${w-d}</span>`
          }else if (w == d) {
            return `<span style="color: #eda634;">${w-d}</span>`
          }else{
            return `<span style="color: #f00;">${w-d}</span>`
          }
        }else{
          return item[column]
        }

      }
    }
  };
</script>
<style>
  .status-icon{
    width: 20px !important;
    height: 20px !important;
    font-size: 7px !important;
    border-radius: 50% !important;
    display: inline-block !important;
  }
  .blockUser{
    background: rgba(82,95,127,0.2);
  }
</style>
