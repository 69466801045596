<template>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
        <tr>
          <slot name="columns">
            <th v-for="(column, index) in columnth" :key="column">
                {{ fomatColum(column) }}
            </th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <template v-if="data.length > 0">
          <tr v-for="(item, index) in data" :key="index" :class="item.type">
            <slot :row="item">
                <td>{{ index + 1 }}</td>
              <td
                v-for="(column, index) in columns"
                :key="index"
                v-if="hasValue(item, column)"
                v-html="itemValue(item, column)"
              ></td>
            </slot>
          </tr>
          <tr>
            <td>
            </td>
            <td>
              <strong>ยอดรวมทั้งหมด</strong>
            </td>
            <td v-for="(column, index) in (Array.isArray(columns) ? columns.slice(1) : [])" :key="index">
              {{ formattedTotals[column] }}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td></td>
            <td :colspan="columns.length" class="text-center">
              ไม่มีรายการ
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </template>
  <script>
  import utils from "@/utils";
  export default {
    name: "base-table-transection1",
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns",
      },
      columnth: {
        type: Array,
        default: () => [],
        description: "Table columns",
      },
      sort: {
        type: Object,
        default: () => {},
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data",
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type",
      },
      theadClasses: {
        type: String,
        default: "",
        description: "<thead> css classes",
      },
      tbodyClasses: {
        type: String,
        default: "",
        description: "<tbody> css classes",
      },
    },
    computed: {
      formattedTotals() {
        return this.columns.reduce((acc, column) => {
          acc[column] = this.data.reduce((sum, item) => {
            return sum + (item[column] || 0);
          }, 0);
          return acc;
        }, {});
      },
      tableClass() {
        return this.type && `table-${this.type}`;
      },
    },
    methods: {
      showDetails(transactions,name) {
        this.$emit("showDetails", {transactions:transactions,name:name});
      },
      formatDate(timestamp) {
        let timeS = timestamp ? new Date(timestamp) : new Date();
        let d = timeS,
          hour = d.getHours(),
          minutes = d.getMinutes(),
          seconds = d.getSeconds(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return `${[year, month, day].join("-")} ${hour}:${minutes}:${seconds}`;
      },
      fomatColum(column) {
        return column; //this.title[column];
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        if (column == "created_at" || column == "updated_at") {
          return utils.forMatDateTimes(new Date(item[column]));
        }else {
          return item[column];
        }
      },
      SortTable(column) {
        this.$emit("SortTable", column);
      },
    },
  };
  </script>
  <style scoped>
  .deposit {
    background: rgb(45 206 137 / 30%) !important;
  }
  .withdraw {
    background: rgb(245 54 92 / 30%) !important;
  }
  </style>
  