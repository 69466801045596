<template>
  <div class="sidebar"
       :data="backgroundColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a href="#/dashboard"
           aria-label="sidebar mini logo"
           class="simple-text logo-mini">
          <div class="logo-img"
               :class="{'logo-img-rtl': $rtl.isRTL}">
            <img src="https://dl.co.th/front/template/default/public/image/icon/icon-welfare-11.png" alt="">
          </div>
        </a>
        <a href="#/dashboard" class="simple-text logo-normal">
          {{title}}
        </a>
      </div>
      <slot>

      </slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link v-for="(link,index) in sidebarLinks"
                        :key="index"
                        :to="link.path"
                        :name="link.name"
                        :icon="link.icon">
          </sidebar-link>
        </slot>
      </ul>
      <b-button class="floating-button" @click="openChat">
      💬 Live Chat
    </b-button>
    </div>
  </div>
</template>
<script>
  import SidebarLink from "./SidebarLink";
  import axios from 'axios';
  import config from '@/config.js';
  export default {
    props: {
      title: {
        type: String,
        default: "ADMIN PANEL"
      },
      backgroundColor: {
        type: String,
        default: "primary"
      },
      activeColor: {
        type: String,
        default: "success",
        validator: value => {
          let acceptedValues = [
            "primary",
            "info",
            "success",
            "warning",
            "danger"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose,
        addLink: this.addLink,
        removeLink: this.removeLink
      };
    },
    components: {
      SidebarLink
    },
    computed: {
      /**
       * Styles to animate the arrow near the current active sidebar link
       * @returns {{transform: string}}
       */
      arrowMovePx() {
        return this.linkHeight * this.activeLinkIndex;
      },
      shortTitle() {
        return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase();
      }
    },
    data() {
      return {
        linkHeight: 65,
        activeLinkIndex: 0,
        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,
        links: [],
        liveChat:{},
        urlLivechat: "",
        bofInfo:{}
      };
    },
    methods: {
      findActiveLink() {
        this.links.forEach((link, index) => {
          if (link.isActive()) {
            this.activeLinkIndex = index;
          }
        });
      },
      addLink(link) {
        const index = this.$slots.links.indexOf(link.$vnode);
        this.links.splice(index, 0, link);
      },
      removeLink(link) {
        const index = this.links.indexOf(link);
        if (index > -1) {
          this.links.splice(index, 1);
        }
      },
      openChat() {
        window.open(this.urlLivechat, "_blank");
      },
      LoadData(){
        axios(config.api('get', '/livechat/Livechat')).then((response)=>{
            this.liveChat = response.data.ResultData.livechat;
            this.bofInfo = response.data.ResultData.bofInfo;
            this.urlLivechat = `https://chat.a886.xyz/auth/admin?department=${this.liveChat.departament}&name=${this.bofInfo.name}&username=${this.bofInfo.user}&password=${this.bofInfo.pass}`
        },(error)=>{
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
        });
      },
    },
    mounted() {
      this.$watch("$route", this.findActiveLink, {
        immediate: true
      });
      this.LoadData();
    }
  };
</script>
<style scoped>
.floating-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: linear-gradient(135deg, #a833ff, #e040fb);;
  color: white;
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.floating-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}
</style>
