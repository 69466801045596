<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns"> 
        <th v-for="(column,index) in columnth" :key="column"> 
          <button class="sorttable" @click="SortTable(columns[index])"> 
            {{fomatColum(column)}}
            <i v-show="sort.col != column && (sort.even == 'desc' || sort.even == 'aec')" class="fa-solid fa-sort"></i>
            <i v-show="sort.col == column && sort.even == 'desc'" class="fa-solid fa-sort-up"></i>
            <i v-show="sort.col == column && sort.even == 'aec'" class="fa-solid fa-sort-down"></i>
          </button>
        </th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in data" :key="index" :class="item.type">
      <slot :row="item"> 
        <td v-for="(column, index) in columns" :key="index"   v-if="hasValue(item, column)" v-html="itemValue(item, column)" @click="opendetail(item)"></td>
      </slot>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import utils from '@/utils';
  export default {
    name: 'base-table-costomer-info',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      columnth: {
        type: Array,
        default: () => [],
        description: "Table columns"
      }, 
      sort:{
        type: Object,
        default: () => {},
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      title: {
        type: String,
        default: 'รายการ', 
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      formatDate(timestamp){
        let timeS = (timestamp)? new Date(timestamp) : new Date();
        let d = timeS,
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(), 
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
      },
      opendetail(item){
        //console.log(item);
        if(item.refer.col == 'lose_log'){
          this.$emit('OpenDetail',item);
        }
        if(item.refer.col == 'get_money_friends'){
          //console.log(item.refer.id);
          window.location.href = `/?#/friendspointdetail?id=${item.refer.id}`
        }
      },
      fomatColum(column){
        return column //this.title[column];
      },
      isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) { 
        if(column == 'created_at' || column ==  'updated_at'){
          return utils.forMatDateTimes(new Date(item[column]));
        }else if(column == 'event'){
          let EVENNAME = {deposit:'ฝาก',withdraw:'ถอน',modify:'มีการแก้ไขจากผู้ดูแล',usepro:'ใช้โปร',transfers:'โยกกระเป๋า',lose:'ยอดเสีย',wheel:'วงล้อ',friends:'เพื่อน',fail_topup:'โยกจากรายการฝากไม่สำเร็จ',admin_topup:'เพิ่มเงินโดยแอดมิน'}
          let TYPENAME = {'deposit':`<h5 style="font-weight: bold;">${EVENNAME[item[column]]}</h5>`,'withdraw':`<h5 style="font-weight: bold;">${EVENNAME[item[column]]}</h5>`}
          return TYPENAME[item['type']]; 
        }else if(column == 'type'){
          let EVENNAME = {'deposit':`<img src="/img/icons_sys/credit.png" style="width: 35px;" />`,'withdraw':`<img src="/img/icons_sys/debit.png" style="width: 35px;" />`}
          return EVENNAME[item[column]];
        }else if(column == 'amount'){
          let EVENNAME = {'deposit':`<h5 style="font-weight: bold;">${item[column]}</h5>`,'withdraw':`<h5 style="font-weight: bold;">${item[column]}</h5>`}
          return EVENNAME[item['type']];
        }else if(column == 'refer'){
          try {
            if(item[column].col == 'lose_log' || item[column].col == 'get_money_friends'){
              return `<span style="cursor: pointer; color: blue; text-decoration: underline;">${item[column].col}-${item[column].id}</span>`;
            }
            return `${item[column].col}-${item[column].id}`;
          } catch (error) {
            return '-'
          }
        }else{
          return item[column];
        }  
      },
      SortTable(column){ 
        this.$emit('SortTable',column);
      }
    }
  };
</script>
<style scoped>
  .deposit{
    background: rgb(45 206 137 / 30%) !important;
  }
  .withdraw{
    background: rgb(245 54 92 / 30%) !important;
  }
</style>
